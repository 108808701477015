"use client";

import { Attributes, Children, cloneElement, FC, isValidElement, PropsWithChildren, useRef } from "react";
import { useWindowSize } from "react-use";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

const NUMBERS_CLASSNAME = "a-number";

export type AnimationNumberProps = PropsWithChildren & {
    from?: number;
    offset?: number;
    parent?: boolean;
};

export const AnimationNumber: FC<AnimationNumberProps> = ({ from = 0, offset = 0, parent, children }) => {
    const refComponent = useRef<HTMLDivElement & { defaultHTML?: string }>(null);

    const { width } = useWindowSize();

    useGSAP(
        () => {
            if (!refComponent.current || !/\d/.test(refComponent.current.innerHTML)) {
                return;
            }

            if (!refComponent.current.defaultHTML) {
                refComponent.current.defaultHTML = refComponent.current.innerHTML;
            }

            refComponent.current.innerHTML = refComponent.current.defaultHTML.replace(
                /(\d+)/g,
                `<span class="inline-block ${NUMBERS_CLASSNAME}">$&</span>`
            );

            const $numbers = refComponent.current.querySelectorAll(`.${NUMBERS_CLASSNAME}`);

            $numbers.forEach($num => {
                if (!$num.textContent) {
                    return;
                }

                const anim = {
                    val: from,
                    valMax: parseFloat($num.textContent.replace(",", ".")),
                };

                gsap.set($num, { minWidth: $num.getBoundingClientRect().width });

                $num.textContent = String(anim.val);

                gsap.to(anim, {
                    val: anim.valMax,
                    ease: "none",
                    duration: 1,
                    scrollTrigger: {
                        trigger: $num,
                        start: `bottom+=${offset} bottom`,
                    },
                    onUpdate() {
                        $num.textContent = String(anim.val.toFixed(0));
                    },
                });
            });
        },
        {
            scope: refComponent,
            revertOnUpdate: true,
            dependencies: [width],
        }
    );

    return (
        <>
            {Children.map(children, child => {
                if (isValidElement(child) && !parent) {
                    return cloneElement(child, {
                        ref: refComponent,
                    } as Attributes);
                }

                return <div ref={refComponent}>{child}</div>;
            })}
        </>
    );
};
