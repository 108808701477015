"use client";

import { useEffect, useRef, useState } from "react";
import { isSafari } from "react-device-detect";
import { Keyboard, Mousewheel, Navigation } from "swiper/modules";
import type { SwiperProps, SwiperRef } from "swiper/react";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper/types";

import { twcx } from "@Shared/utils";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/mousewheel";
import "swiper/css/keyboard";

import styles from "./styles.module.scss";

type SliderPropsClassNames = {
    slide?: string;
};

export type SliderProps = SwiperProps & {
    classNames?: SliderPropsClassNames;
    slides: React.ReactNode[];
};

export const Slider: React.FC<SliderProps> = ({ className, classNames, slides, onSwiper, ...restProps }) => {
    const refComponent = useRef<SwiperRef>(null);

    const [swiper, setSwiper] = useState<SwiperType | null>(null);

    useEffect(() => {
        swiper?.update();
    }, [swiper]);

    useEffect(() => {
        if (refComponent.current?.swiper && refComponent.current.swiper.mousewheel?.enabled && isSafari) {
            refComponent.current.swiper.mousewheel?.disable();
        }
    }, []);

    return (
        <Swiper
            ref={refComponent}
            className={twcx(styles.component, className)}
            modules={[Navigation, Mousewheel, Keyboard]}
            grabCursor
            keyboard
            mousewheel={{
                forceToAxis: true,
            }}
            onSwiper={s => {
                setSwiper(s);

                onSwiper?.(s);
            }}
            {...restProps}
        >
            {slides.map((slide, slideIndex) => (
                <SwiperSlide key={slideIndex} className={classNames?.slide}>
                    {slide}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
