export const ENDPOINTS = {
    articles: "/articles",
    forms: "/forms",
    modals: "/modals",
    pagesSimple: "/page-simples",
    projects: "/projects",
    projectsCategories: "/project-categories",
    reviews: "/reviews",
    socials: "/socials",
    common: "/common",
    footer: "/footer",
    header: "/header",
    navigation: "/navigation",
    pageHome: "/page-home",
    pageNotFound: "/page-not-found",
    pageProjects: "/page-projects",
    formsSend: "/ezforms/submit",
};
