export const PAGES = {
    home: "/",
    projects: "/projects",
    projectsCategory: (slug: string) => `/projects/${slug}`,
    about: "/about",
    blog: "/blog",
    contact: "/contact",
    privacyPolicy: "/privacy-policy",
    project: (slug: string) => `/projects/${slug}`,
    article: (slug: string) => `/blog/${slug}`,
};
