import { twcx } from "@Shared/utils";

type SplitTextParams = {
    element: HTMLElement & { defaultTextContent?: string | null };
    classNameWord?: string;
    classNameChar?: string;
};

export const splitText = ({ element, classNameWord, classNameChar }: SplitTextParams) => {
    if (!element.defaultTextContent) {
        element.defaultTextContent = element.textContent;
    }

    const text = element.defaultTextContent;
    const splittedText = text
        ?.split(" ")
        .map(
            word =>
                `<span class="${twcx("word inline-block text-nowrap", classNameWord)}">${word
                    .split("")
                    .map(char => `<span class="${twcx("char inline-block", classNameChar)}">${char}</span>`)
                    .join("")}</span>`
        )
        .join(" ");

    if (splittedText) {
        element.innerHTML = splittedText;
    }
};
