"use client";

import { forwardRef, useRef } from "react";
import { useWindowSize } from "react-use";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

import { Text, TextProps } from "@Shared/ui";
import { splitText } from "@Shared/utils";

type AnimationTextFillingProps = TextProps & {
    opacityStart?: number;
    offset?: number;
};

export const AnimationTextFilling = forwardRef<HTMLDivElement, AnimationTextFillingProps>(
    ({ opacityStart = 0.15, offset = 50, ...restProps }, ref) => {
        const refText = useRef<HTMLDivElement | null>(null);

        const { width } = useWindowSize();

        useGSAP(
            () => {
                if (!refText.current) {
                    return;
                }

                splitText({
                    element: refText.current,
                    classNameChar: "inline",
                });

                const $chars = refText.current.querySelectorAll(".char");

                gsap.from($chars, {
                    transition: "none",
                    opacity: opacityStart,
                    ease: "none",
                    stagger: 0.5,
                    scrollTrigger: {
                        trigger: refText.current,
                        start: `top+=${offset} bottom`,
                        end: `+=${innerHeight / 1.25}`,
                        scrub: 0.5,
                    },
                });
            },
            {
                scope: refText,
                revertOnUpdate: true,
                dependencies: [width],
            }
        );

        return (
            <Text
                ref={node => {
                    refText.current = node;

                    if (typeof ref === "function") {
                        ref(node);
                    } else if (ref) {
                        ref.current = node;
                    }
                }}
                {...restProps}
            />
        );
    }
);

AnimationTextFilling.displayName = "AnimationTextFilling";
