import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import { CollectionProject, CollectionProjectCategory, ResponseCollection, ResponseData } from "../types";

export class ProjectsApi extends Repository {
    getProjectsData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionProject>> = await this.client.request.get(
            ENDPOINTS.projects,
            {
                sort: ["sort:desc", "id:desc"],
                fields: [
                    "slug",
                    "sort",
                    "name",
                    "description",
                    "data",
                    "badges",
                    "externalLink",
                    "useExternalLinkForPreview",
                ],
                populate: "imagePreview,imagePreviewWide,imagePreviewDevices",
                ...params,
            }
        );

        return {
            data: result?.data ?? [],
            pagination: result?.meta?.pagination ?? null,
        };
    };

    getProjectsCategoriesData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionProjectCategory>> = await this.client.request.get(
            ENDPOINTS.projectsCategories,
            {
                sort: ["sort:desc", "id:desc"],
                ...params,
            }
        );

        return result?.data ?? [];
    };
}
