import { forwardRef, Fragment } from "react";

import { Link, Text, TextProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type BreadcrumbsPropsClassNames = {
    item?: string;
    link?: string;
    divider?: string;
};

export type BreadcrumbsPropsItem = { link?: string; text: string };

export type BreadcrumbsProps = TextProps & {
    classNames?: BreadcrumbsPropsClassNames;
    items: BreadcrumbsPropsItem[];
};

export const Breadcrumbs = forwardRef<HTMLDivElement, BreadcrumbsProps>(
    ({ className, classNames, items, ...restProps }, ref) => {
        if (!items.length) {
            return null;
        }

        return (
            <Text
                ref={ref}
                className={twcx("flex flex-wrap items-center gap-1", className)}
                variant="p2"
                weight={500}
                color="white"
                {...restProps}
            >
                {items.map((item, index) => (
                    <Fragment key={index + item.text}>
                        <div className={twcx("shrink-0", classNames?.item)}>
                            {item.link ? (
                                <Link
                                    className={twcx(
                                        "text-gray-05",
                                        "lg:transition-colors lg:[&:not(:disabled)]:hover:text-blue-light",
                                        classNames?.link
                                    )}
                                    href={item.link}
                                >
                                    {item.text}
                                </Link>
                            ) : (
                                <>{item.text}</>
                            )}
                        </div>

                        {index !== items.length - 1 && <div className={twcx("shrink-0", classNames?.divider)}>/</div>}
                    </Fragment>
                ))}
            </Text>
        );
    }
);

Breadcrumbs.displayName = "Breadcrumbs";
